import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { AttributesSlottable } from 'types/utils'

const BaseViewHead = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>
>(({ children, className, asChild, ...props }, forwardedRef) => {
  const Component = asChild ? Slot : 'header'
  return (
    <Component
      className={cn('flex h-[4.5rem] gap-large items-center', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Component>
  )
})

BaseViewHead.displayName = 'BaseViewHead'

export default BaseViewHead
