import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { AttributesSlottable } from 'types/utils'

const BaseViewBody = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>
>(({ children, className, asChild, ...props }, forwardedRef) => {
  const Component = asChild ? Slot : 'section'
  return (
    <Component
      className={cn(
        'grid col-span-full grid-rows-[auto_fit-content(100%)] z-10 sm:content-start',
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Component>
  )
})

BaseViewBody.displayName = 'BaseViewBody'

export default BaseViewBody
