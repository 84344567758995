import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { AttributesSlottable } from 'types/utils'

const BaseViewFooter = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>
>(({ children, className, asChild, ...props }, forwardedRef) => {
  const Component = asChild ? Slot : 'footer'
  return (
    <Component
      className={cn(
        'flex flex-col py-larger gap-y-small gap-x-main',
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Component>
  )
})

BaseViewFooter.displayName = 'BaseViewFooter'

export default BaseViewFooter
