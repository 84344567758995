import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { AttributesSlottable } from 'types/utils'

const BaseViewBackground = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>
>(({ children, className, asChild, ...props }, forwardedRef) => {
  const Component = asChild ? Slot : 'div'
  return (
    <Component
      className={cn('absolute top-0 left-0 right-0 bottom-0', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Component>
  )
})

BaseViewBackground.displayName = 'BaseViewBackground'

export default BaseViewBackground
