import { cn, Progress, ProgressIndicator } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { UIMatch } from '@remix-run/react'
import { useMatches } from '@remix-run/react'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { AttributesSlottable } from 'types/utils'
import type { StepConfig } from '~/factories/flowManager.factory.server'

type MatchesData = {
  steps: StepConfig[]
}

const BaseViewStepper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>
>(({ className, asChild, ...props }, forwardedRef) => {
  const Component = asChild ? Slot : 'div'

  const matches = useMatches() as UIMatch<{ steps?: MatchesData['steps'] }>[]

  // Get the steps configuration from any loader that has it.
  const { steps } = matches.reduce(
    (acc, match) => {
      const routeData = match?.data
      if (routeData?.steps) acc.steps = routeData.steps

      return acc
    },
    { steps: [] } as MatchesData,
  )

  const currentStep = steps.find(step => step.state === 'current')

  if (!currentStep) return null

  return (
    <Component
      className={cn('w-full flex flex-col gap-y-smallest', className)}
      {...props}
      ref={forwardedRef}
    >
      <h2 className="typography-caption-strong fg-expressive-main group-data-[variant=expressive]/baseview:fg-inverted-main">
        {currentStep.title}
      </h2>
      <Progress progress={currentStep.progress}>
        <ProgressIndicator className="group-data-[variant=expressive]/baseview:bg-expressive-inverted" />
      </Progress>
    </Component>
  )
})

BaseViewStepper.displayName = 'BaseViewStepper'

export default BaseViewStepper
